import Markdown, { Components } from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

import { Heading } from "../Heading";
import { Text, TextSize } from "../Text";

type RichTextProps = {
  readonly text: string;
  readonly size?: TextSize;
};

type ComponentMapProps = Omit<RichTextProps, "text">;

export const markdownComponentMap: (
  extraProps: ComponentMapProps,
) => Components = (extraProps) => ({
  h1: (props) => <Heading as="h1">{props.children}</Heading>,
  h2: (props) => <Heading as="h2">{props.children}</Heading>,
  h3: (props) => <Heading as="h3">{props.children}</Heading>,
  p: (props) => <Text {...props} {...extraProps} />,
});

export function RichText({ text, size = "md" }: RichTextProps) {
  return (
    <Markdown
      components={markdownComponentMap({ size })}
      rehypePlugins={[rehypeRaw, remarkGfm]}
      disallowedElements={["script", "style"]}
    >
      {text}
    </Markdown>
  );
}
