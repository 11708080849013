/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import { DynamicContentType } from "@/dep_types/content/dynamic-content";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

import ProductSlider from "@/dep_components/Commons/Products/ProductSlider/ProductSlider";
import FilteredFAQs from "@/dep_components/FAQ/FilteredFAQs";
import BrandPartnershipHero from "@/dep_components/Sections/BrandPartnershipHero";
import { BrandValueProp } from "@/dep_components/Sections/BrandValueProp";
import CategoryTiles from "@/dep_components/Sections/CategoryTiles";
import HowItWorks from "@/dep_components/Sections/HowItWorks";
import MediaValueProp from "@/dep_components/Sections/MediaValueProp";

import HeroImage from "../Sections/HeroImage";

export type ExtraPropsType = {
  [key: string]: any;
};

type Props = {
  componentData: DynamicContentType;
  extraProps: ExtraPropsType;
};

const { COMPONENT_KEYS } = commonKeys;

const sectionComponents = {
  [COMPONENT_KEYS.RECOMMENDED_PRODUCTS]: ProductSlider,
  [COMPONENT_KEYS.BRAND_VALUE_PROP]: BrandValueProp,
  [COMPONENT_KEYS.CATEGORY_TILES]: CategoryTiles,
  [COMPONENT_KEYS.HOW_RENTALS_WORK]: HowItWorks,
  [COMPONENT_KEYS.BRAND_PARTNERSHIP_HERO]: BrandPartnershipHero,
  [COMPONENT_KEYS.MEDIA_VALUE_PROP]: MediaValueProp,
  [COMPONENT_KEYS.FAQ]: FilteredFAQs,
  [COMPONENT_KEYS.HERO]: HeroImage,
};

const DynamicContent: FC<Props> = ({
  componentData,
  extraProps = {},
}: Props) => {
  const { componentKey, ...props } = componentData;

  const DynamicComponent = sectionComponents[componentKey];
  const componentExists = Boolean(DynamicComponent);

  if (componentExists) {
    const hasExtraProps = componentKey in extraProps;
    const moreProps = hasExtraProps ? extraProps[componentKey] : {};

    return <DynamicComponent {...props} {...moreProps} />;
  }

  return null;
};

// eslint-disable-next-line import/no-default-export
export default DynamicContent;
