import { Box, Flex, Heading, useMultiStyleConfig } from "@chakra-ui/react";
import Link from "next/link";
import React, { FC } from "react";

import TrackingService from "@/dep_services/tracking/trackingService";
import { CategoryTilesType } from "@/dep_types/content/sections";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

import Container from "@/dep_components/Layout/Container";
import Section from "@/dep_components/Layout/Section";

type Props = Omit<CategoryTilesType, "componentKey" | "id">;

const CategoryTiles: FC<Props> = ({ title, tiles }) => {
  const styles = useMultiStyleConfig("CategoryTiles");

  return (
    <Section as="section" color="black">
      <Container>
        <Heading size="lg" sx={styles.title}>
          {title}
        </Heading>
        <Flex
          justifyContent="center"
          flexDirection={{
            base: "column",
            md: "row",
          }}
          gap={{
            base: "5",
            md: "4",
          }}
        >
          {tiles.map(({ title: tileTitle, url, image }) => {
            const mobileImageURL = image
              ? `${commonKeys.CLOUDINARY_BASE_IMAGE_URL}/f_auto%2Cw_650%2Ch_650%2Cdpr_2%2Cc_fill/${image.cloudinaryId}${image.extension}`
              : "";
            const tabletImageURL = image
              ? `${commonKeys.CLOUDINARY_BASE_IMAGE_URL}/f_auto%2Cw_173%2Ch_172%2Cdpr_2%2Cc_fill/${image.cloudinaryId}${image.extension}`
              : "";
            const desktopImageURL = image
              ? `${commonKeys.CLOUDINARY_BASE_IMAGE_URL}/f_auto%2Cw_313%2Ch_312%2Cdpr_2%2Cc_fill/${image.cloudinaryId}${image.extension}`
              : "";
            const imageGradient =
              "linear-gradient( rgba(29, 31, 34, 0.5), rgba(29, 31, 34, 0.5) )";

            const backgroundImageStyles = image
              ? {
                  base: `${imageGradient}, url(${mobileImageURL})`,
                  md: `${imageGradient}, url(${tabletImageURL})`,
                  xl: `${imageGradient}, url(${desktopImageURL})`,
                }
              : undefined;

            return (
              <Link href={url} passHref key={tileTitle} prefetch={false}>
                <Box
                  as="a"
                  flexGrow={1}
                  flexBasis={{ md: 0 }}
                  sx={styles.card}
                  onClick={() =>
                    TrackingService.trackButtonClick(
                      `Category Tile - ${tileTitle}`,
                      "Homepage",
                    )
                  }
                  backgroundImage={backgroundImageStyles}
                >
                  {tileTitle}
                </Box>
              </Link>
            );
          })}
        </Flex>
      </Container>
    </Section>
  );
};

// eslint-disable-next-line import/no-default-export
export default CategoryTiles;
