import { ResponsiveValue } from "@chakra-ui/react";
import { Property } from "csstype";

import { ResponsiveImageType } from "@/dep_types/content/components";
import { ResponsiveImageDimensions } from "@/dep_types/responsive-image";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

type CloudinaryIdsType = {
  mobile?: string;
  tablet?: string;
  desktop?: string;
};

type ScreenSize = "mobile" | "tablet" | "desktop";

const getBackgroundImageString = (
  cloudinaryIds: CloudinaryIdsType,
  responsiveImageDimensions: ResponsiveImageDimensions,
  screenSize: ScreenSize,
): string => {
  const imageDimensions = responsiveImageDimensions[screenSize];
  let cloudinaryId = cloudinaryIds[screenSize];

  if (!cloudinaryId) {
    switch (screenSize) {
      case "tablet":
        cloudinaryId = cloudinaryIds.mobile ?? cloudinaryIds.desktop;
        break;
      case "desktop":
        cloudinaryId = cloudinaryIds.tablet ?? cloudinaryIds.mobile;
        break;
      default:
        cloudinaryId = cloudinaryIds.tablet ?? cloudinaryIds.desktop;
        break;
    }
  }

  return `linear-gradient( rgba(29, 31, 34, 0.5), rgba(29, 31, 34, 0.5) ), url(${commonKeys.CLOUDINARY_BASE_IMAGE_URL}/f_auto%2Cw_${imageDimensions.width}%2Ch_${imageDimensions.height}%2Cdpr_2%2Cc_fill/${cloudinaryId})`;
};

const getBackgroundImageStyles = (
  responsiveImageDimensions: ResponsiveImageDimensions,
  image: ResponsiveImageType,
): ResponsiveValue<Property.BackgroundImage> | undefined => {
  const hasMobileImage = Boolean(image.mobile?.cloudinaryId);
  const hasTabletImage = Boolean(image.tablet?.cloudinaryId);
  const hasDesktopImage = Boolean(image.desktop?.cloudinaryId);
  const hasImage = hasMobileImage || hasTabletImage || hasDesktopImage;

  if (!hasImage) {
    return undefined;
  }

  const cloudinaryIds = {
    mobile: image.mobile?.cloudinaryId,
    tablet: image.tablet?.cloudinaryId,
    desktop: image.desktop?.cloudinaryId,
  };

  return {
    base: getBackgroundImageString(
      cloudinaryIds,
      responsiveImageDimensions,
      "mobile",
    ),
    md: getBackgroundImageString(
      cloudinaryIds,
      responsiveImageDimensions,
      "tablet",
    ),
    xl: getBackgroundImageString(
      cloudinaryIds,
      responsiveImageDimensions,
      "desktop",
    ),
  };
};

// eslint-disable-next-line import/no-default-export
export default getBackgroundImageStyles;
