import { Box } from "@chakra-ui/react";
import React, { FC, ReactElement } from "react";

import CheckIcon from "@/dep_components/icons/CheckIcon";
import ReleaseIcon from "@/dep_components/icons/Yeti/ReleaseIcon";
import RenewIcon from "@/dep_components/icons/Yeti/RenewIcon";
import RescueIcon from "@/dep_components/icons/Yeti/RescueIcon";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

type Props = {
  index: number;
};

const getYetiLiIcon = (index: number): ReactElement => {
  switch (index) {
    case 0:
      return <RescueIcon marginRight={6} />;
    case 1:
      return <RenewIcon marginRight={6} />;
    default:
      return <ReleaseIcon marginRight={6} />;
  }
};

const MediaValuePropListIcon: FC<Props> = ({ index }) => {
  const { isYeti, isCitizenry } = useWhitelabelContext();

  if (isYeti) {
    const icon = getYetiLiIcon(index);

    return <Box>{icon}</Box>;
  }
  if (isCitizenry) {
    return (
      <Box marginRight={6}>
        <Box
          mt="12px"
          width="8px"
          height="8px"
          borderRadius="50%"
          backgroundColor="gray.500"
        />
      </Box>
    );
  }

  return (
    <Box marginRight={6}>
      <CheckIcon />
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default MediaValuePropListIcon;
