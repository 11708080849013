/* eslint-disable sonarjs/cognitive-complexity */
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  List,
  Text,
  useBreakpointValue,
  useMultiStyleConfig,
  useStyleConfig,
} from "@chakra-ui/react";
import { Image } from "cloudinary-react";
import { FC, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

import layoutConstants from "@/dep_constants/layout";
import isCategoryEnabledFor from "@/dep_helpers/content/isCategoryEnabledFor";
import { MediaValuePropType } from "@/dep_types/content/sections";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

import { LinkedCTAButton } from "@/dep_components/Commons/Buttons/LinkedCTAButton";
import { RichText } from "@/dep_components/Commons/RichText";
import Container from "@/dep_components/Layout/Container";
import Section from "@/dep_components/Layout/Section";
import MediaValuePropListIcon from "@/dep_components/Sections/MediaValuePropListIcon";

import useIsMobile from "@/dep_hooks/useIsMobile";

interface Props extends Omit<MediaValuePropType, "componentKey" | "id"> {
  productCategory?: string;
}

const MediaValueProp: FC<Props> = ({
  mediaItem,
  mediaPlacement,
  title,
  description,
  checkListItems,
  button,
  categoriesEnabledFor,
  productCategory,
}) => {
  const styles = useMultiStyleConfig("MediaValueProp");
  const videoElementRef = useRef<HTMLVideoElement>(null);
  const [hasBeenViewed, setHasBeenViewed] = useState(false);
  const { ref: mediaItemContainerRef } = useInView({
    onChange: (inView) => {
      if (inView) {
        setHasBeenViewed(true);
        videoElementRef.current?.play();
      } else {
        videoElementRef.current?.pause();
      }
    },
    rootMargin: !hasBeenViewed ? "50px" : "0px",
  });

  const descriptionStyles = useStyleConfig("Text", {
    size: "lg",
  });

  const isMobile = useIsMobile();

  const mediaItemSizeProps = useBreakpointValue(
    {
      base: {
        width: 375,
        height: 264,
      },
      md: {
        width: 365,
        height: 448,
      },
      lg: {
        width: 480,
        height: 500,
      },
      xl: {
        width: 680,
        height: 500,
      },
      "2xl": {
        width: 840,
        height: 500,
      },
    },
    "base",
  );

  const isMediaItemFirst = mediaPlacement === "first";
  const mediaItemOrder = isMediaItemFirst ? 0 : 1;
  const mediaItemJustifyContent = isMediaItemFirst ? "flex-start" : "flex-end";
  const cloudinaryHtmlWidth = isMobile ? "100%" : undefined;

  const shouldRender = productCategory
    ? isCategoryEnabledFor(categoriesEnabledFor, productCategory)
    : true;

  // Initial placeholder for media component to avoid layout shift when we lazy load
  const fallbackMediaItemComponent = (
    <Box
      height={mediaItemSizeProps?.height}
      width={mediaItemSizeProps?.width}
    />
  );
  let mediaItemComponent = fallbackMediaItemComponent;

  if (mediaItem) {
    if (mediaItem.type === "video") {
      const cloudinaryTransformations = `c_fill%2Cdpr_2.0%2Cf_auto%2Ch_${mediaItemSizeProps?.height}%2Cw_${mediaItemSizeProps?.width}`;
      const cloudinaryBaseUrl = `${commonKeys.CLOUDINARY_BASE_VIDEO_URL}/${cloudinaryTransformations}/v1/${mediaItem.cloudinaryId}`;

      mediaItemComponent = (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          ref={videoElementRef}
          poster={`${cloudinaryBaseUrl}.jpg`}
          height={mediaItemSizeProps?.height}
          width={isMobile ? "100%" : mediaItemSizeProps?.width}
          autoPlay
          muted
          playsInline
          loop
        >
          <source src={`${cloudinaryBaseUrl}.webm`} type="video/webm" />
          <source src={`${cloudinaryBaseUrl}.mp4`} type="video/mp4" />
          <source src={`${cloudinaryBaseUrl}.ogv`} type="video/ogg" />
        </video>
      );
    } else if (mediaItem.type === "image") {
      mediaItemComponent = (
        <Image
          publicId={mediaItem.cloudinaryId}
          crop="fill"
          dpr={2.0}
          htmlWidth={cloudinaryHtmlWidth}
          alt={mediaItem.altText}
          {...mediaItemSizeProps}
        />
      );
    }
  }

  const finalMediaItemComponent = hasBeenViewed
    ? mediaItemComponent
    : fallbackMediaItemComponent;

  return shouldRender ? (
    <Section data-testid="mediaValuePropContainer">
      <Container>
        <Grid templateColumns="repeat(12, 1fr)" columnGap={5} rowGap={10}>
          <GridItem
            ref={mediaItemContainerRef}
            data-testid="mediaItemContainer"
            display="flex"
            justifyContent={mediaItemJustifyContent}
            alignItems="flex-start"
            gridColumn={{
              base: "span 12",
              md: "span 6",
            }}
            paddingLeft={{
              xl: isMediaItemFirst ? 0 : 6,
              "2xl": isMediaItemFirst ? 0 : 8,
            }}
            paddingRight={{
              xl: isMediaItemFirst ? 6 : 0,
              "2xl": isMediaItemFirst ? 8 : 0,
            }}
            marginLeft={{
              base: `-${layoutConstants.containerPadding.base}`,
              md: isMediaItemFirst
                ? `-${layoutConstants.containerPadding.md}`
                : 0,
              xl: isMediaItemFirst
                ? `-${layoutConstants.containerPadding.xl}`
                : 0,
              "2xl": 0,
            }}
            marginRight={{
              base: `-${layoutConstants.containerPadding.base}`,
              md: isMediaItemFirst
                ? 0
                : `-${layoutConstants.containerPadding.md}`,
              xl: isMediaItemFirst
                ? 0
                : `-${layoutConstants.containerPadding.xl}`,
              "2xl": 0,
            }}
            order={mediaItemOrder}
          >
            {finalMediaItemComponent}
          </GridItem>
          <GridItem
            gridColumn={{
              base: "span 12",
              md: "span 6",
            }}
          >
            <Heading size="lg" sx={styles.title}>
              {title}
            </Heading>
            <RichText
              text={description}
              sx={{
                ...descriptionStyles,
                ...styles.body,
              }}
            />
            {checkListItems.length > 0 && (
              <List
                marginBottom={{
                  base: 8,
                  xl: 14,
                }}
              >
                {checkListItems.map((checkListItem, index) => (
                  <Text
                    key={checkListItem.id}
                    as="li"
                    size="lg"
                    _notLast={{
                      marginBottom: 2,
                    }}
                    sx={styles.listItem}
                  >
                    <Flex>
                      <MediaValuePropListIcon index={index} />
                      <RichText text={checkListItem.text} />
                    </Flex>
                  </Text>
                ))}
              </List>
            )}
            {button && <LinkedCTAButton {...button} />}
          </GridItem>
        </Grid>
      </Container>
    </Section>
  ) : null;
};

MediaValueProp.defaultProps = {
  productCategory: undefined,
};

// eslint-disable-next-line import/no-default-export
export default MediaValueProp;
