import { Flex, Grid, GridItem, Heading, useTheme } from "@chakra-ui/react";
import { useKeenSlider } from "keen-slider/react";
import React, { FC } from "react";

import { HowItWorksType } from "@/dep_types/content/sections";

import { LinkedCTAButton } from "@/dep_components/Commons/Buttons/LinkedCTAButton";
import { RichText } from "@/dep_components/Commons/RichText";
import Container from "@/dep_components/Layout/Container";
import Section from "@/dep_components/Layout/Section";

type Props = Omit<HowItWorksType, "componentKey" | "id">;

const HowItWorks: FC<Props> = ({ title, button, steps }) => {
  const { breakpoints } = useTheme();

  const [ref] = useKeenSlider<HTMLUListElement & HTMLDivElement>({
    slides: {
      perView: 1,
    },
    breakpoints: {
      [`(min-width: ${breakpoints.md})`]: {
        slides: {
          perView: 2,
        },
      },
      [`(min-width: ${breakpoints.xl})`]: {
        slides: {
          perView: 3,
        },
      },
    },
  });

  return (
    <Section color="black" overflow="hidden">
      <Container>
        <Grid
          templateColumns="repeat(12, 1fr)"
          columnGap="5"
          rowGap="4"
          fontFamily="normal"
          alignItems="center"
        >
          <Heading
            size="lg"
            gridColumn="1 / span 12"
            marginBottom={{ base: "3.5", xl: "10" }}
            textAlign={{ base: "left", md: "center" }}
          >
            {title}
          </Heading>
          <GridItem
            ref={ref}
            as="ul"
            className="keen-slider"
            gridColumn={{ base: "1 / span 10", xl: "1 / span 12" }}
            marginBottom={{ base: "12", xl: "16" }}
            overflow="visible !important"
          >
            {steps.map(({ title: stepTitle, description }, index) => (
              <Flex
                as="li"
                direction="column"
                className="keen-slider__slide"
                _notLast={{
                  paddingRight: { base: 8, md: 16 },
                }}
                key={stepTitle}
              >
                <Flex
                  border="1px solid"
                  borderRadius="50%"
                  borderColor="gray.200"
                  width="43px"
                  height="43px"
                  align="center"
                  justify="center"
                  marginBottom="5"
                  fontWeight="medium"
                  fontSize="16px"
                  lineHeight="1"
                >
                  {index + 1}
                </Flex>
                <Heading
                  as="h3"
                  marginBottom="4"
                  fontWeight="medium"
                  fontSize={{ base: "16px", xl: "28px" }}
                  lineHeight="1.48"
                  letterSpacing="-.03em"
                >
                  {stepTitle}
                </Heading>
                <RichText
                  fontWeight="normal"
                  fontSize="16px"
                  lineHeight="1.48"
                  letterSpacing="-.03em"
                  text={description}
                />
              </Flex>
            ))}
          </GridItem>
          <GridItem
            gridColumn={{
              base: "1 / span 9",
              md: "5 / span 4",
              xl: "6 / span 2",
            }}
          >
            <LinkedCTAButton {...button} />
          </GridItem>
        </Grid>
      </Container>
    </Section>
  );
};

// eslint-disable-next-line import/no-default-export
export default HowItWorks;
