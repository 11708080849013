import { Box } from "@chakra-ui/react";
import { KeenSliderInstance } from "keen-slider/react";
import React, { FC } from "react";

import getSliderProgressBar from "@/dep_helpers/slider/getSliderProgressBar";

type Props = {
  slider: KeenSliderInstance;
};

const SliderProgressBar: FC<Props> = ({ slider }) => {
  const sliderProgressBar = getSliderProgressBar(slider);

  if (!sliderProgressBar) {
    return null;
  }

  return (
    <Box
      position="relative"
      width={sliderProgressBar.width}
      height="4px"
      backgroundColor="gray.200"
      borderRadius="2px"
      transition=".25s ease left"
      left={sliderProgressBar.position}
      marginTop={6}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default SliderProgressBar;
