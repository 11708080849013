import { KeenSliderInstance } from "keen-slider/react";

const getSlidesPerView = (slider: KeenSliderInstance): number => {
  // If we are unable to find a specific value for slidesPerView then
  // we can assume that there is only one slide per view
  let slidesPerView = 1;

  // Try to find a value for slidesPerView based on the keen slider data
  if (typeof slider.options.slides === "object") {
    const slidesPerViewValue = slider.options.slides?.perView;

    if (typeof slidesPerViewValue === "number") {
      slidesPerView = slidesPerViewValue;
    } else if (typeof slidesPerViewValue === "function") {
      const slidesPerViewFunctionValue = slidesPerViewValue();

      if (typeof slidesPerViewFunctionValue === "number") {
        slidesPerView = slidesPerViewFunctionValue;
      }
    }
  }

  return slidesPerView;
};

// eslint-disable-next-line import/no-default-export
export default getSlidesPerView;
