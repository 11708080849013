/* eslint-disable */
import React, { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

const RescueIcon: FC<IconProps> = ({ ...other }) => {
  return (
    <Icon
      width="34px"
      height="26px"
      viewBox="0 0 34 26"
      {...other}>
      <path d="M25.4943 9.66271H20.3373V4.50565C20.3373 4.22651 20.1109 4 19.8317 4H14.1683C13.8892 4 13.6627 4.22644 13.6627 4.50565V9.66271H8.50565C8.22651 9.66271 8 9.88915 8 10.1684V15.8316C8 16.1108 8.22644 16.3373 8.50565 16.3373H13.6627V21.4943C13.6627 21.7735 13.8891 22 14.1683 22H19.8317C20.1108 22 20.3373 21.7736 20.3373 21.4943V16.3373H25.4943C25.7735 16.3373 26 16.1109 26 15.8316V10.1684C26 9.88922 25.7736 9.66271 25.4943 9.66271ZM24.9888 15.326H19.8317C19.5526 15.326 19.3261 15.5524 19.3261 15.8316V20.9887H14.6741V15.8316C14.6741 15.5525 14.4476 15.326 14.1684 15.326H9.01138V10.6739H14.1684C14.4475 10.6739 14.6741 10.4475 14.6741 10.1684V5.01131H19.3261V10.1684C19.3261 10.4475 19.5525 10.6739 19.8317 10.6739H24.9888C24.9888 10.6739 24.9888 15.326 24.9888 15.326Z" fill="#002B45"/>
    </Icon>
  );
};

// eslint-disable-next-line import/no-default-export
export default RescueIcon;
