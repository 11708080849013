import { Box, Grid, GridItem, useMultiStyleConfig } from "@chakra-ui/react";
import { Image } from "cloudinary-react";
import React, { FC } from "react";

import getBackgroundImageStyles from "@/dep_helpers/content/getBackgroundImageStyles";
import { BrandPartnershipHeroType } from "@/dep_types/content/sections";
import { ResponsiveImageDimensions } from "@/dep_types/responsive-image";

import { LinkedCTAButton } from "@/dep_components/Commons/Buttons/LinkedCTAButton";
import { RichText } from "@/dep_components/Commons/RichText";
import Container from "@/dep_components/Layout/Container";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

type Props = Omit<BrandPartnershipHeroType, "componentKey" | "id"> & {
  responsiveImageDimensions?: ResponsiveImageDimensions;
  headingType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

const defaultImageDimensions = {
  mobile: {
    width: 375,
    height: 554,
  },
  tablet: {
    width: 834,
    height: 726,
  },
  desktop: {
    width: 1440,
    height: 584,
  },
};

const BrandPartnershipHero: FC<Props> = ({
  title,
  description,
  button,
  image,
  brandImages,
  responsiveImageDimensions = defaultImageDimensions,
  headingType,
}) => {
  const { isYeti } = useWhitelabelContext();

  const styles = useMultiStyleConfig("BrandPartnershipHero", {});

  const backgroundImageStyles = getBackgroundImageStyles(
    responsiveImageDimensions,
    image,
  );

  return (
    <Container
      as="section"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundImage={backgroundImageStyles}
      backgroundSize="cover"
      sx={styles.section}
      minHeight={{
        base: `${responsiveImageDimensions.mobile.height}px`,
        md: `${responsiveImageDimensions.tablet.height}px`,
        xl: `${responsiveImageDimensions.desktop.height}px`,
      }}
    >
      <Grid templateColumns="repeat(12, 1fr)" columnGap={5} rowGap={6}>
        {brandImages && brandImages.length > 0 && (
          <GridItem sx={styles.brands}>
            {brandImages.map((brandImage) => (
              <Box key={brandImage.cloudinaryId} mr={4}>
                <Image
                  alt=""
                  publicId={brandImage.cloudinaryId}
                  crop="limit"
                  width={160}
                  height={30}
                />
              </Box>
            ))}
          </GridItem>
        )}
        <GridItem as={headingType} sx={styles.title}>
          {title}
        </GridItem>
        {description && (
          <GridItem sx={styles.subtitle}>
            {isYeti ? description : <RichText text={description} />}
          </GridItem>
        )}
        {button && (
          <GridItem sx={styles.button}>
            <LinkedCTAButton {...button} />
          </GridItem>
        )}
      </Grid>
    </Container>
  );
};

BrandPartnershipHero.defaultProps = {
  responsiveImageDimensions: defaultImageDimensions,
  headingType: "h2",
};

// eslint-disable-next-line import/no-default-export
export default BrandPartnershipHero;
