import { TextMultipleType } from "@/dep_types/content/components";

const isCategoryEnabledFor = (
  categoriesEnabledFor: TextMultipleType[],
  categoryNameToMatch: string,
): boolean =>
  categoriesEnabledFor.length === 0 ||
  categoriesEnabledFor.some(
    (category) =>
      category.name.toLowerCase() === categoryNameToMatch.toLowerCase(),
  );

// eslint-disable-next-line import/no-default-export
export default isCategoryEnabledFor;
