import React, { FC } from "react";

import getEnabledItemsByCategory from "@/dep_helpers/content/getEnabledItemsByCategory";
import { FAQSectionType } from "@/dep_types/content/sections";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

import FAQ from ".";

type Props = Omit<FAQSectionType, "componentKey" | "id"> & {
  productCategory?: string;
};

const FilteredFAQs: FC<Props> = ({ title, faqs, button, productCategory }) => {
  const filteredFAQs = getEnabledItemsByCategory(
    faqs || [],
    productCategory || commonKeys.ALL_CATEGORY,
  );

  return <FAQ title={title} faqs={filteredFAQs} button={button} />;
};

FilteredFAQs.defaultProps = {
  productCategory: commonKeys.ALL_CATEGORY,
};

// eslint-disable-next-line import/no-default-export
export default FilteredFAQs;
