/* eslint-disable @typescript-eslint/no-explicit-any */
import { HasEnabledCategoriesType } from "@/dep_types/content/sections";
import { commonKeys } from "@/dep_whitelabel/commonKeys";

import isCategoryEnabledFor from "./isCategoryEnabledFor";

type Item = Omit<HasEnabledCategoriesType, "active"> & {
  [key: string]: any;
};

const getEnabledItemsByCategory = (
  items: Item[],
  categoryName: string,
): any[] => {
  let enabledItems = items.filter((item) =>
    isCategoryEnabledFor(item.categoriesEnabledFor, categoryName),
  );

  if (enabledItems.length === 0) {
    // If nothing found, we look for any props matching `all`
    enabledItems = items.filter((item) =>
      isCategoryEnabledFor(item.categoriesEnabledFor, commonKeys.ALL_CATEGORY),
    );
  }

  return enabledItems;
};

// eslint-disable-next-line import/no-default-export
export default getEnabledItemsByCategory;
