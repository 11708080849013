import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  useMultiStyleConfig,
  useStyleConfig,
} from "@chakra-ui/react";
import { FC } from "react";

import { FAQType } from "@/dep_types/content/components";

import { RichText } from "@/dep_components/Commons/RichText";

type Props = Omit<FAQType, "id" | "categoriesEnabledFor">;

const QuestionAccordionItem: FC<Props> = ({ question, answer }) => {
  const styles = useMultiStyleConfig("FAQValueProp");
  const textStyles = useStyleConfig("Text", {
    size: "lg",
  });

  return (
    <AccordionItem borderTop="none" padding={0}>
      <AccordionButton
        display="flex"
        justifyContent="space-between"
        paddingLeft={0}
        paddingRight={4}
        paddingBottom={2}
        _focus={{
          boxShadow: "outline",
        }}
        _hover={{
          backgroundColor: "none",
        }}
      >
        <Text as="h3" size="lg" sx={styles.question}>
          {question}
        </Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel paddingY={7} paddingX={0}>
        <RichText text={answer} sx={{ ...textStyles, ...styles.answer }} />
      </AccordionPanel>
    </AccordionItem>
  );
};

// eslint-disable-next-line import/no-default-export
export default QuestionAccordionItem;
