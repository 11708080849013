import { KeenSliderInstance } from "keen-slider/react";

import getSlidesPerView from "@/dep_helpers/slider/getSlidesPerView";

type SliderProgressBar = {
  width: string;
  position: string;
};

const getSliderProgressBar = (
  slider: KeenSliderInstance | null,
): SliderProgressBar | null => {
  if (!slider) {
    return null;
  }

  const slidesPerView = getSlidesPerView(slider);

  const progressBarPosition =
    (slider.track.details.rel / slider.slides.length) * 100;
  const progressBarWidth = (slidesPerView / slider.slides.length) * 100;
  const hasProgressBar = progressBarWidth < 100;

  if (!hasProgressBar) {
    return null;
  }

  return {
    width: `${progressBarWidth}%`,
    position: `${progressBarPosition}%`,
  };
};

// eslint-disable-next-line import/no-default-export
export default getSliderProgressBar;
