import {
  Accordion,
  Grid,
  GridItem,
  Heading,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { FC } from "react";

import { FAQSectionType } from "@/dep_types/content/sections";

import { LinkedCTAButton } from "@/dep_components/Commons/Buttons/LinkedCTAButton";
import Container from "@/dep_components/Layout/Container";
import Section from "@/dep_components/Layout/Section";

import QuestionAccordionItem from "./QuestionAccordionItem";

type Props = Omit<FAQSectionType, "componentKey" | "id">;

const FAQ: FC<Props> = ({ title, faqs, button }) => {
  const hasFAQs = faqs && faqs.length > 0;
  const styles = useMultiStyleConfig("FAQValueProp");

  if (!hasFAQs) {
    return null;
  }

  return (
    <Section>
      <Container>
        <Grid templateColumns="repeat(12, 1fr)" columnGap={5} rowGap={10}>
          <GridItem gridColumn="span 12" textAlign="center">
            <Heading size="lg" sx={styles.title}>
              {title}
            </Heading>
          </GridItem>
          <Accordion
            display="grid"
            gridColumn="span 12"
            gridTemplateColumns="repeat(12, 1fr)"
            columnGap={5}
            rowGap={6}
            allowMultiple
          >
            {faqs.map(({ id, question, answer }) => (
              <GridItem
                key={id}
                gridColumn={{
                  base: "span 12",
                  md: "span 6",
                }}
              >
                <QuestionAccordionItem question={question} answer={answer} />
              </GridItem>
            ))}
          </Accordion>
          {button && (
            <GridItem
              gridColumn="span 12"
              display="flex"
              justifyContent="center"
            >
              <LinkedCTAButton {...button} />
            </GridItem>
          )}
        </Grid>
      </Container>
    </Section>
  );
};

// eslint-disable-next-line import/no-default-export
export default FAQ;
