import { Center, Icon } from "@chakra-ui/react";
import React, { FC, MouseEventHandler, ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick: MouseEventHandler;
  isDisabled: boolean;
  iconDetails: {
    width: number;
    height: number;
  };
  marginLeft?: number;
  marginRight?: number;
};

const ToggleSlideButton: FC<Props> = ({
  children,
  iconDetails,
  marginLeft,
  marginRight,
  isDisabled,
  onClick,
}: Props) => {
  const iconColor = isDisabled ? "gray.200" : "black";
  const cursorValue = isDisabled ? "not-allowed" : "pointer";

  return (
    <Center
      as="button"
      type="button"
      disabled={isDisabled}
      onClick={onClick}
      width={{ base: "25px", md: "30px", lg: "40px" }}
      height={{ base: "25px", md: "30px", lg: "40px" }}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="50%"
      marginLeft={marginLeft ?? 0}
      marginRight={marginRight ?? 2.5}
      cursor={cursorValue}
      transition="border-color .25s ease"
      _hover={{
        borderColor: "black",
        _disabled: {
          borderColor: "gray.200",
        },
      }}
    >
      <Icon
        width={`${iconDetails.width}px`}
        height={`${iconDetails.height}px`}
        viewBox={`0 0 ${iconDetails.width} ${iconDetails.height}`}
        fill="none"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transition="stroke ease .15s"
      >
        {children}
      </Icon>
    </Center>
  );
};

ToggleSlideButton.defaultProps = {
  marginLeft: undefined,
  marginRight: undefined,
};

// eslint-disable-next-line import/no-default-export
export default ToggleSlideButton;
