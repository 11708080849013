import { Box, Flex, Grid, GridItem, useStyleConfig } from "@chakra-ui/react";

import getBackgroundImageStyles from "@/dep_helpers/content/getBackgroundImageStyles";
import ProductSaleTypeEnum from "@/dep_types/commerce-api/productSaleType";
import { HeroType } from "@/dep_types/content/homepage";
import { RichText } from "@/revive/components/RichText";

import { LinkedCTAButton } from "@/dep_components/Commons/Buttons/LinkedCTAButton";
import Container from "@/dep_components/Layout/Container";
import { useWhitelabelContext } from "@/dep_components/whitelabelContext";

import { useFeatureFlags } from "@/dep_hooks/cms/useFeatureFlags";

const responsiveImageDimensions = {
  mobile: {
    width: 375,
    height: 504,
  },
  tablet: {
    width: 834,
    height: 726,
  },
  desktop: {
    width: 1440,
    height: 650,
  },
};

function HeroImage({
  title,
  richTextTitle,
  description,
  image,
  rentalButton,
  resaleButton,
}: HeroType) {
  const titleStyles = useStyleConfig("Title", {
    size: "hero",
    variant: "hero",
  });

  const {
    isBurton,
    saleType: partnerSaleType,
    isYeti,
  } = useWhitelabelContext();
  const { data: featureFlags } = useFeatureFlags();

  const subheadingStyles = useStyleConfig("Heading", {
    size: "hero_subHeading",
    variant: "hero",
  });

  const backgroundImageStyles = getBackgroundImageStyles(
    responsiveImageDimensions,
    image,
  );

  const isRentAvailable =
    partnerSaleType === ProductSaleTypeEnum.BOTH ||
    partnerSaleType === ProductSaleTypeEnum.RENTAL;

  const buttonResaleOverrides: { border?: string } = {};

  if (isYeti) {
    buttonResaleOverrides.border = "none";
  }

  const baseGridColumns = "1 / span 12";

  return (
    <Container
      as="section"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundImage={backgroundImageStyles}
      backgroundSize="cover"
    >
      <Grid
        templateColumns="repeat(12, 1fr)"
        columnGap="5"
        alignContent="center"
        minHeight={{
          base: "504px",
          md: "726px",
          xl: "650px",
        }}
      >
        <Box
          sx={titleStyles}
          gridColumn={{ base: baseGridColumns }}
          justifySelf={{ md: "center" }}
          maxWidth={{
            md: "680px",
            xl: "800px",
          }}
          marginBottom={6}
          textAlign={{
            base: "left",
            md: "center",
          }}
          verticalAlign="top"
          display="flex"
          flexDirection="row"
        >
          {richTextTitle ? (
            <RichText text={richTextTitle} />
          ) : (
            <h1
              style={{
                letterSpacing: isYeti ? "3px" : "inherit",
                lineHeight: isYeti ? "64px" : "inherit",
              }}
            >
              {title}
            </h1>
          )}
        </Box>
        <GridItem
          gridColumn={{
            base: baseGridColumns,
            md: "4 / span 6",
            xl: "5 / span 4",
          }}
          marginBottom={{
            base: 5,
            md: 7,
            xl: 10,
          }}
          textAlign={{
            base: "left",
            md: "center",
          }}
          color="white"
          sx={subheadingStyles}
          as="h2"
        >
          {description}
        </GridItem>
        <GridItem
          gridColumn={{ base: baseGridColumns }}
          justifySelf={{
            base: isYeti ? "left" : "inherit",
            md: "center",
          }}
          width={{
            md: "430px",
          }}
        >
          <Flex justifyContent="center">
            {isRentAvailable && (
              <Box minWidth={resaleButton ? "50%" : "202px"}>
                <LinkedCTAButton {...rentalButton} />
              </Box>
            )}
            {resaleButton &&
              ((isBurton && featureFlags?.showGearFinder) || !isBurton) && (
                <Box minWidth="50%" ml={isRentAvailable ? 4 : 0}>
                  <Flex alignItems="center" justifyContent="center">
                    <LinkedCTAButton
                      {...resaleButton}
                      sx={buttonResaleOverrides}
                    />
                  </Flex>
                </Box>
              )}
          </Flex>
        </GridItem>
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line import/no-default-export
export default HeroImage;
