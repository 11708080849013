import { Icon, IconProps } from "@chakra-ui/react";
import React, { FC } from "react";

const CheckIcon: FC<IconProps> = ({ ...other }) => (
  <Icon
    width="13px"
    height="10px"
    viewBox="0 0 13 10"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.25"
    {...other}
  >
    <path d="M12 1L4.4375 9L1 5.36364" />
  </Icon>
);

// eslint-disable-next-line import/no-default-export
export default CheckIcon;
