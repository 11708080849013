import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  useMultiStyleConfig,
  useTheme,
} from "@chakra-ui/react";
import { useKeenSlider } from "keen-slider/react";
import Link from "next/link";
import React, { FC } from "react";

import getSlidesPerView from "@/dep_helpers/slider/getSlidesPerView";
import TrackingService from "@/dep_services/tracking/trackingService";
import { SearchProductType } from "@/dep_types";
import EventLocationType from "@/dep_types/EventLocationType";
import { RecommendedProductsType } from "@/dep_types/content/sections";

import { ProductCard } from "@/dep_components/Commons/Products/ProductCard/ProductCard";
import ToggleSlideButton from "@/dep_components/Commons/Products/ProductSlider/ToggleSlideButton";
import SliderProgressBar from "@/dep_components/Commons/SliderProgressBar";
import Container from "@/dep_components/Layout/Container";
import Section from "@/dep_components/Layout/Section";

import useIsMobile from "@/dep_hooks/useIsMobile";

type DynamicProps = Omit<
  RecommendedProductsType,
  "componentKey" | "id" | "productType"
>;
type ExtraProps = {
  products: SearchProductType[];
  eventLocation: EventLocationType;
  eventName: string;
};

const ProductSlider: FC<DynamicProps & ExtraProps> = ({
  products,
  eventLocation,
  eventName,
  title,
  titleLink,
  description,
  variant,
}) => {
  const totalProducts = products.length;

  const styles = useMultiStyleConfig("ProductSlider", { variant });

  const isValueProp = variant === "value_prop";

  const isMobile = useIsMobile();

  const hasTwoCardsPerSlide = isMobile && isValueProp;
  const slides = hasTwoCardsPerSlide
    ? products.reduce(
        (slideList: SearchProductType[][], product): SearchProductType[][] => {
          const previousSlide = slideList[slideList.length - 1];

          if (previousSlide && previousSlide.length === 1) {
            previousSlide.push(product);
          } else {
            slideList.push([product]);
          }

          return slideList;
        },
        [],
      )
    : products;

  const totalSlides = slides.length;

  const [currentSlide, setCurrentSlide] = React.useState(0);

  const { breakpoints } = useTheme();
  const [sliderRef, slider] = useKeenSlider<HTMLUListElement & HTMLDivElement>({
    slides: {
      perView: isValueProp ? 1 : 2,
    },
    slideChanged(s) {
      const newCurrentSlide = s.track.details.rel;

      TrackingService.trackEvent(eventName);
      setCurrentSlide(newCurrentSlide);
    },
    breakpoints: {
      [`(min-width: ${breakpoints.md})`]: {
        slides: {
          perView: 3,
        },
      },
      [`(min-width: ${breakpoints.lg})`]: {
        slides: {
          perView: isValueProp ? 3 : 4,
        },
      },
    },
  });
  const slidesPerView = slider.current ? getSlidesPerView(slider.current) : 1;

  const sectionTitle = (
    <Heading as="h2" size="lg" sx={styles.title}>
      {title}
    </Heading>
  );

  if (totalProducts === 0) {
    return null;
  }

  return (
    <Section sx={styles.section}>
      <Container>
        <Grid
          templateColumns="repeat(12, 1fr)"
          columnGap="5"
          rowGap={{
            base: 4,
            md: 5,
            xl: 6,
          }}
          fontFamily="normal"
          alignItems="center"
        >
          <GridItem sx={styles.navigation}>
            {!isValueProp && title && titleLink && (
              <Link href={titleLink} passHref prefetch={false}>
                <Flex as="a" role="group" sx={styles.linkedTitle}>
                  {sectionTitle}
                  <Icon
                    width="8px"
                    height="14px"
                    viewBox="0 0 8 14"
                    fill="none"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    _groupHover={{ stroke: "gray.500" }}
                  >
                    <path d="M1 13L7 7L1 1" />
                  </Icon>
                </Flex>
              </Link>
            )}
            {!isValueProp && title && !titleLink && (
              <GridItem sx={styles.valueProp}>
                {sectionTitle}
                <Box
                  display={{
                    base: "none",
                    md: "block",
                  }}
                >
                  {description}
                </Box>
              </GridItem>
            )}
            {slider.current && slidesPerView < totalSlides && (
              <Flex sx={styles.toggleButtons}>
                <ToggleSlideButton
                  onClick={() => slider.current?.prev()}
                  iconDetails={{
                    width: 7,
                    height: 9,
                  }}
                  isDisabled={currentSlide === 0}
                >
                  <path d="M5.35742 0.785706L1.64314 4.49999L5.35742 8.21428" />
                </ToggleSlideButton>
                <ToggleSlideButton
                  onClick={() => slider.current?.next()}
                  iconDetails={{
                    width: 6,
                    height: 10,
                  }}
                  isDisabled={currentSlide === totalSlides - slidesPerView}
                >
                  <path d="M1 8.42859L4.71429 4.7143L1 1.00002" />
                </ToggleSlideButton>
              </Flex>
            )}
          </GridItem>
          {isValueProp && (
            <GridItem sx={styles.valueProp}>
              {sectionTitle}
              <Box
                display={{
                  base: "none",
                  md: "block",
                }}
              >
                {description}
              </Box>
            </GridItem>
          )}
          <GridItem sx={styles.sliderContainer}>
            <Box
              ref={sliderRef}
              as="ul"
              className="keen-slider"
              sx={styles.slider}
            >
              {slides.map((slide: SearchProductType | SearchProductType[]) => {
                if (Array.isArray(slide)) {
                  return (
                    <Flex
                      as="li"
                      className="keen-slider__slide"
                      direction="column"
                      key={slide[0].id}
                      paddingRight={{
                        base: 5,
                        md: 2.5,
                        xl: 5,
                      }}
                    >
                      {slide.map((product) => (
                        <ProductCard
                          location={eventLocation}
                          product={product}
                          shouldShowPrice
                          key={product.id}
                        />
                      ))}
                    </Flex>
                  );
                }

                return (
                  <Box
                    as="li"
                    className="keen-slider__slide"
                    paddingRight={{
                      base: 2.5,
                      xl: 5,
                    }}
                    key={slide.id}
                  >
                    <ProductCard
                      location={eventLocation}
                      product={slide}
                      shouldShowPrice
                    />
                  </Box>
                );
              })}
            </Box>
            {slider.current && <SliderProgressBar slider={slider.current} />}
          </GridItem>
        </Grid>
      </Container>
    </Section>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProductSlider;
