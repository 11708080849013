/* eslint-disable */
import React, { FC } from "react";
import { Icon, IconProps } from "@chakra-ui/react";

const RenewIcon: FC<IconProps> = ({ ...other }) => {
  return (
    <Icon
      width="34px"
      height="26px"
      viewBox="0 0 34 26"
      {...other}>
      <path d="M27.8474 11.8767L25.2637 9.69863L27.6023 7.52743C27.7164 7.42133 27.7648 7.26119 27.728 7.10925C27.6911 6.95872 27.575 6.83895 27.4235 6.79683L20.9224 5.0157C20.7763 4.97569 20.6234 5.01535 20.5183 5.11634L18.2957 7.18768L15.9045 5.10716C15.7953 5.00996 15.6447 4.97541 15.5014 5.01788L6.30697 7.71147C6.15682 7.75667 6.04317 7.8798 6.01004 8.03314C5.97655 8.18578 6.02947 8.34521 6.14858 8.44957L8.66775 10.6318L6.1578 12.6908C6.04002 12.7869 5.98271 12.9351 6.00458 13.0874C6.02674 13.2369 6.12369 13.3618 6.26503 13.4214L8.90505 14.5126V17.8983C8.90505 18.0715 9.00746 18.2269 9.16648 18.2946L15.4782 20.9671C15.5315 20.989 15.5871 21 15.6438 21C15.6963 21 15.7489 20.9904 15.8028 20.9699L24.7744 17.372C24.9389 17.3056 25.0447 17.1489 25.0447 16.9723V13.677L27.7294 12.6056C27.8679 12.5505 27.9697 12.4242 27.9949 12.2734C28.018 12.1248 27.9613 11.9729 27.8474 11.8767ZM19.1414 7.57354L20.9264 5.90876L26.4535 7.42196L24.5053 9.23049L19.1414 7.57354ZM18.7346 11.426V8.3476L23.276 9.75063L18.7346 11.426ZM16.0727 13.9658L18.469 16.0484C18.5884 16.1524 18.7604 16.1839 18.9093 16.1236L24.1865 14.0185V16.6814L16.0727 19.935V13.9658ZM26.7464 12.0717L18.8336 15.2292L16.4731 13.1764L24.5299 10.2044L26.7464 12.0717ZM17.8765 8.35376V11.7425L15.6464 12.5651L10.6388 10.6738L17.8765 8.35376ZM9.7623 14.8672L12.5129 16.0039C12.6603 16.0641 12.8351 16.0361 12.9576 15.93L15.215 13.9658V19.9221L9.7623 17.613V14.8672ZM14.8194 13.1715L12.5955 15.1073L7.25613 12.9009L9.4142 11.1307L14.8194 13.1715ZM7.30527 8.31543L15.5177 5.90911L17.4478 7.58861L9.43224 10.1571L7.30527 8.31543Z" fill="#002B45"/>
      <path d="M16.5999 19.2105L18.9151 18.3047V16.5048L16.5999 17.3588V19.2105Z" fill="#002B45"/>
    </Icon>
  );
};

// eslint-disable-next-line import/no-default-export
export default RenewIcon;
